import React, { useState } from 'react';

import { Outlet } from 'react-router-dom';

import { Box, Tabs } from '@tf/ui';

import { EntityRelationsTable } from '@/components/entities/EntityRelationsTable';
import { SideMenuLayout } from '@/components/shared';

import { Menu } from './Menu';

const TABS = {
	entity: 'Entity',
	relations: 'Relations',
};

export const AccountEntityLayout = () => {
	const [active, setActiveTab] = useState<string>('entity');
	return (
		<>
			<Box sx={({ breakpoints }) => ({ maxWidth: breakpoints.xl, margin: '0 auto' })}>
				<Tabs
					variant="normal"
					value={active}
					styles={({ colors }) => ({
						tab: {
							padding: '.65rem 1rem',
							borderRadius: 0,
							"&[data-active='true']": {
								fontWeight: 500,
								color: colors.brand[6],
								backgroundColor: colors.brand[0],
								borderBottom: `1.5px solid ${colors.brand[6]}`,
							},
						},
						list: { borderBottom: `1px solid ${colors.gray[2]}` },
					})}
					onChange={(value) => {
						if (!value) return;
						setActiveTab(value);
					}}
				>
					<Tabs.List>
						{Object.entries(TABS).map(([value, label]) => {
							return (
								<Tabs.Tab key={value} value={value}>
									<Box>{label}</Box>
								</Tabs.Tab>
							);
						})}
					</Tabs.List>

					<Tabs.Panel value="entity">
						<SideMenuLayout menu={<Menu />}>
							<Outlet />
						</SideMenuLayout>
					</Tabs.Panel>

					<Tabs.Panel value="relations">
						<Box mt=".75rem">
							<EntityRelationsTable />
						</Box>
					</Tabs.Panel>
				</Tabs>
			</Box>
		</>
	);
};

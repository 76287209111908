import React, { useEffect, useState } from 'react';

import type { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import {
	Box,
	Button,
	createStyles,
	Divider,
	Flex,
	Icon,
	Stack,
	TFCard,
	TFLoadingOverlay,
	TFMarkdownText,
	TFMarkdownTextEditor,
	TFNotifier,
	TFText,
	Tooltip,
} from '@tf/ui';
import { D, fmt, S, T } from '@tf/utils';

import {
	useClientCaseRequest,
	useCloseClientCaseRequestMutation,
	useDownloadClientCaseRequestFileMutation,
	useRevokeClientCaseRequestMutation,
	useSendClientCaseRequestEmailMutation,
	useUpdateClientCaseRequestMutation,
} from '@/core/api/caseRequests';
import { useAccountParams, useSelectedAccount } from '@/core/hooks';
import { getDisplayName } from '@/core/utils';

import { ClientEmail } from './ClientEmail';
import { DueDate } from './DueDate';
import { LivenessCheckResults } from './LivenessCheckResults';
import { clientCaseKindLabels } from './requestKind';
import { extractUniqueIndividualEntities } from './utils';

const useStyles = createStyles(({ colors, radius, fontSizes }) => {
	const borderColor = colors.gray[3];

	return {
		wrapper: {
			paddingTop: '0.5rem',
			paddingBottom: '0.5rem',
			display: 'flex',
			borderRadius: radius.sm,

			'& > :not(:first-of-type)': {
				borderLeft: `1px solid ${borderColor}`,
			},
		},
		item: {
			paddingLeft: '0.75rem',
			paddingRight: '0.75rem',
			minWidth: '100px',
			fontWeight: 500,
			maxWidth: '160px',
		},
		tooltipContent: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		itemTitle: {
			color: colors.gray[5],
			fontWeight: 600,
			fontSize: '0.75rem',
			letterSpacing: '.5px',
		},
		divider: {
			borderColor,
			borderWidth: '0.5px',
		},
		dateContainer: {
			color: '#64748B',
			fontWeight: 400,
			justifyContent: 'end',
			minWidth: '200px',
			textAlign: 'end',
		},
		markdownContainer: {
			flexGrow: 1,
		},
		markdown: {
			'& > :last-of-type': {
				marginBottom: 0,
			},
		},
	};
});

export const EditClientCase = () => {
	const [editorContent, setEditorContent] = useState('');

	const account = useSelectedAccount();
	const navigate = useNavigate();

	const applicantReview = account.reviews.find((r) => r.connectionKind === 'APPLICANT');
	T.assertNotNullish(applicantReview, 'no applicant review found');

	const individualEntities = extractUniqueIndividualEntities(account.reviews);

	const { classes } = useStyles();
	const { accountId, requestId } = useAccountParams();
	const { data: caseRequest, isSuccess } = useClientCaseRequest(accountId, requestId);
	const updateClientCaseMutation = useUpdateClientCaseRequestMutation(accountId);
	const revokeClientCaseMutation = useRevokeClientCaseRequestMutation(accountId);
	const sendClientCaseRequestEmailMutation = useSendClientCaseRequestEmailMutation(accountId);
	const downloadClientCaseRequestFileMutation = useDownloadClientCaseRequestFileMutation();
	const closeClientCaseRequestMutation = useCloseClientCaseRequestMutation(accountId);

	// it's just a tinny hack to render initial value in markdown editor
	const [editorKey, setEditorKey] = useState<'rendered'>();
	const [selectedDate, setSelectedDate] = useState<Date>();

	const handleDownloadFile = async (fileStorageKey: string, fileName: string) => {
		if (!caseRequest) return;

		const blob = await downloadClientCaseRequestFileMutation.mutateAsync({
			accountId,
			requestId,
			fileStorageKey,
		});

		const url = URL.createObjectURL(new Blob([blob]));
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		a.click();
		window.URL.revokeObjectURL(url);
	};

	useEffect(() => {
		if (!caseRequest?.requestPayload) return;

		if (!editorContent) {
			setEditorContent(caseRequest.requestPayload.text ?? '');
		}

		setEditorKey('rendered');

		if (caseRequest.dueAt) {
			setSelectedDate(new Date(caseRequest.dueAt));
		}
	}, [caseRequest]);

	if (!isSuccess) {
		return <TFLoadingOverlay h={300} label="Case loading..." />;
	}

	const isFreeForm = caseRequest.requestKind === 'FREEFORM';

	const hasTextChanged = editorContent !== caseRequest.requestPayload?.text;
	const hasDueDateChanged = selectedDate && !D.isSameDate(selectedDate, caseRequest.dueAt);
	const isFormChanged = Boolean(hasTextChanged || hasDueDateChanged);

	const freeFormResponse = caseRequest.responsePayload as BackendTypes.ClientCaseRequestFreeformResponse;
	const uploadedFiles = freeFormResponse?.files ?? [];
	const responseText = freeFormResponse?.text ?? '';
	const livenessCheckEntityId = (caseRequest.requestPayload as any)?.livenessCheckEntityId;

	const authorLabel =
		caseRequest.authorId === 'SCHEDULER_ROBOT'
			? 'System'
			: caseRequest.authorUserInfo
			? getDisplayName(caseRequest.authorUserInfo)
			: 'N/A';

	return (
		<>
			<TFCard style={{ marginTop: '.75rem' }}>
				<Flex align="center" justify="space-between">
					<Box className={classes.wrapper}>
						<Item label="Type">{clientCaseKindLabels[caseRequest.requestKind]}</Item>
						<Item label="Email">
							<ClientEmail
								email={caseRequest.clientEmail}
								requestId={requestId}
								data={caseRequest}
								status={caseRequest.status}
							/>
						</Item>
						<Item label="Sent date">
							{caseRequest.sentAt ? fmt.toDate(caseRequest.sentAt, { preset: 'full_date' }) : 'N/A'}
						</Item>
						<Item label="Due date">
							<DueDate
								selectedDate={selectedDate}
								setSelectedDate={setSelectedDate}
								status={caseRequest.status}
							/>
						</Item>
						<Item label="Author">
							<Tooltip label={authorLabel} position="top">
								<Box className={classes.tooltipContent}>{authorLabel}</Box>
							</Tooltip>
						</Item>
						<Item label="Status">{S.prettify(caseRequest.status)}</Item>
					</Box>
					<Flex>
						{caseRequest.status === 'DRAFT' ? (
							<Button
								variant="light"
								disabled={updateClientCaseMutation.isPending || !isFormChanged}
								onClick={async () => {
									await updateClientCaseMutation.mutateAsync(
										{
											accountId,
											requestId: caseRequest.uuid,
											data: {
												dueAt: selectedDate?.toISOString(),
												requestPayload: isFreeForm
													? { text: editorContent }
													: { text: editorContent, livenessCheckEntityId },
												clientEmail: caseRequest.clientEmail,
											},
										},
										{
											onSuccess: () => {
												TFNotifier.success('Changes saved');
											},
										}
									);
								}}
								size="sm"
							>
								Save changes
							</Button>
						) : null}

						{caseRequest.status === 'DRAFT' ? (
							<Button
								onClick={async () => {
									if (!editorContent) {
										TFNotifier.error('Text is required');
										return;
									}

									await updateClientCaseMutation.mutateAsync(
										{
											accountId,
											requestId: caseRequest.uuid,
											data: {
												dueAt: selectedDate?.toISOString(),
												requestPayload: isFreeForm
													? { text: editorContent }
													: { text: editorContent, livenessCheckEntityId },
												clientEmail: caseRequest.clientEmail,
											},
										},
										{
											onSuccess: async () => {
												await sendClientCaseRequestEmailMutation.mutateAsync({
													accountId,
													requestId: caseRequest.uuid,
												});
											},
										}
									);
								}}
								disabled={sendClientCaseRequestEmailMutation.isPending}
								size="sm"
								m="0 .75rem"
							>
								Send request
							</Button>
						) : caseRequest.status === 'SENT' ? (
							<Button
								disabled={revokeClientCaseMutation.isPending}
								onClick={async () =>
									await revokeClientCaseMutation.mutateAsync({ accountId, requestId: caseRequest.uuid })
								}
								size="sm"
								m="0 .75rem"
							>
								Revoke request
							</Button>
						) : null}
					</Flex>
				</Flex>
			</TFCard>

			<TFCard mt="1rem">
				<Box m="1rem">
					{!isFreeForm && (
						<>
							<TFText size="md" fw="600">
								Individual account:
							</TFText>
							<Box m=".5rem 0 1rem">
								{individualEntities.find((e) => e.graphNodeId === livenessCheckEntityId)?.name}
							</Box>
						</>
					)}

					<TFText size="md" fw="600">
						Request message:{' '}
						{caseRequest.status === 'DRAFT' && <span style={{ color: '#fa5252' }}>*</span>}
					</TFText>
					{caseRequest.status === 'DRAFT' ? (
						<Box maw="800px" mt=".5rem">
							<TFMarkdownTextEditor
								key={editorKey}
								content={editorContent}
								onChange={setEditorContent}
							/>
						</Box>
					) : (
						<>
							<MessageContainer editorContent={editorContent} date={caseRequest.sentAt} />
							{(responseText || uploadedFiles.length > 0) && (
								<Divider className={classes.divider} my="sm" />
							)}
						</>
					)}
					{responseText && (
						<>
							<TFText size="md" fw="600">
								Response message:
							</TFText>
							<MessageContainer editorContent={responseText} date={caseRequest.updatedAt} />
							{uploadedFiles.length > 0 && <Divider className={classes.divider} my="sm" />}
						</>
					)}
					{uploadedFiles.length > 0 && (
						<>
							<TFText size="md" fw="600">
								Attached files:
							</TFText>
							{uploadedFiles.map((file, index) => (
								<Button
									variant="light"
									size="sm"
									w="100%"
									mt=".5rem"
									onClick={() => handleDownloadFile(file.storageKey, file.filename)}
									key={index}
									rightSection={<Icon.IconDownload size="18" />}
								>
									{file.filename}
								</Button>
							))}
						</>
					)}
					<LivenessCheckResults graphId={livenessCheckEntityId} />
				</Box>
			</TFCard>
			{caseRequest.status === 'UPDATED' && (
				<Flex mt="1rem" justify="flex-end">
					<Button
						mt="1rem"
						onClick={async () =>
							closeClientCaseRequestMutation.mutateAsync(
								{ requestId },
								{
									onSuccess: () => {
										navigate(`/accounts/${accountId}/cases`);
									},
								}
							)
						}
					>
						Close case
					</Button>
				</Flex>
			)}
		</>
	);
};

const Item: React.FC<PropsWithChildren<{ label: string }>> = ({ label, children }) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.item}>
			<TFText pb="0.15rem" className={classes.itemTitle}>
				{label}
			</TFText>
			{children}
		</Box>
	);
};

const MessageContainer = ({ editorContent, date }: { editorContent: string; date?: string }) => {
	const { classes } = useStyles();
	return (
		<Flex>
			<Flex className={classes.markdownContainer}>
				<TFMarkdownText className={classes.markdown} text={editorContent} />
			</Flex>
			<Stack className={classes.dateContainer}>
				{date ? fmt.toDate(date, { preset: 'full_date_with_time' }) : null}
			</Stack>
		</Flex>
	);
};
